
/** Setting margins */

@page {
    size: A4 landscape;
    margin: 0 0.5cm;
}

html, body, .page {
    width: 297mm !important;
    height: 210mm !important;
    background-color: none !important;
    font-family: Roboto, system-ui, Noto, Helvetica, Arial, sans-serif;
}

.page-content {
    background-color: none !important;
}

h1 {
    font-size:18pt;
}

.block {
    margin-top: 10px;
    font-size: 10pt;
}

.block-title {
    margin-bottom: 0;
}
table {
    border-left: solid 1px #eee !important;
    border-bottom: solid 1px #000000 !important;
    td {
        font-size:10pt;
        height: 22px !important;
    }
    th {
        border-bottom: solid 1px #000000 !important;
    }
}